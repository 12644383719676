<template>
  <div>
    <div v-show="loader">
      <Skeleton width="60" height="1.2rem" class="mb-8" />
      <div v-for="index in 4" :key="index" class="md:mt-16">
        <Skeleton width="60" height="1.2rem" />
        <Skeleton width="60%" height="1.2rem" class="mt-4" />
      </div>
    </div>
    <div v-show="!loader">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold text-lg text-black">Business details</h4>
        <div>
          <Button
            v-if="disabled"
            text="Edit"
            p="py-2 px-6"
            radius="rounded-3xl"
            width="w-28"
            fontSize="text-sm"
            background="bg-lightPurple"
            color="text-brandPurple"
            @click="editInfo()"
          />
          <Button
            v-if="disabled === false"
            text="Save info"
            p="py-2 px-6"
            radius="rounded-3xl"
            width="w-28"
            fontSize="text-sm"
            :loading="loading"
            @click="updateBusinessInfo()"
            :disabled="disableButton"
          />
        </div>
      </div>
      <div>
        <Input
          type="text"
          width="w-full"
          placeholder="Business name"
          class="mt-14"
          :disabled="disabled"
          v-model="businessInfo.name"
          errorText="Business name is required"
          :validation="rules.name"
          @valid="valid.name = $event"
        />
        <Input
          type="text"
          width="w-full"
          placeholder="Business website"
          class="mt-11"
          :disabled="disabled"
          v-model="businessInfo.businessWebsite"
          errorText="Enter a valid website address"
          :validation="rules.website"
          @valid="valid.website = $event"
        />

        <Select
          class="mt-11"
          width="w-full"
          label="name"
          placeholder="Business Category"
          :options="options"
          :reduce="category => category.key"
          v-model="businessInfo.businessCategory"
          :disabled="disabled"
          errorText="Business category is required"
          :validation="rules.category"
          @valid="valid.category = $event"
        />
        <Input
          id="address"
          type="text"
          width="w-full"
          placeholder="Address"
          class="mt-11"
          :disabled="disabled"
          v-model="businessInfo.address"
          @blur="validateAddress()"
          @focus="handleError()"
        />
        <transition name="fade-out">
          <p v-if="errorOnAddress" class="text-brandRed text-xs">
            Please select a valid address from the dropdown
          </p>
        </transition>
      </div>

      <div v-if="!disabled">
        <Input
          type="text"
          width="w-full"
          placeholder="Add more details to this address (if any)"
          class="mt-11"
          v-model="businessInfo.additionalAddressDetails"
        />
        <div class="mt-11 grid grid-cols-2 gap-4 mb-10">
          <Select
            width="w-full"
            label="name"
            placeholder="State"
            :options="states"
            :reduce="category => category.name"
            v-model="businessInfo.state"
            errorText="State is required"
            :validation="rules.state"
            @valid="valid.state = $event"
          />
          <Select
            width="w-full"
            label="name"
            placeholder="LGA"
            :options="lga"
            :reduce="category => category.name"
            v-model="businessInfo.lga"
            errorText="Lga is required"
            :validation="rules.lga"
            @valid="valid.lga = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GET_BUSINESS_INFO,
  GET_BUSINESS_CATEGORIES,
  UPDATE_BUSINESS_INFO
} from "@/utils/api/settings.js";
import { mapActions } from "vuex";
import Input from "@/UI/Input";
import Modal from "@/UI/Modal";
import Button from "@/UI/Button";
import Select from "@/UI/Select";
import Location from "@/database/location.json";

export default {
  components: {
    Input,
    Button,
    Modal,
    Select
  },
  data: () => ({
    disabled: true,
    loading: false,
    options: [],
    states: [],
    lga: [],
    category: "Business Category",
    valid: {
      name: false,
      website: false,
      address: false,
      category: false,
      state: false,
      lga: false
    },
    isAddressValid: true,
    errorOnAddress: false
  }),
  props: {
    businessInfo: {
      type: Object,
      default: () => {},
      required: true
    },
    loader: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    rules() {
      return {
        name: this.businessInfo?.name?.length !== 0,
        website:
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(
            this.businessInfo?.businessWebsite
          ) ||
          this.businessInfo?.businessWebsite?.length === 0 ||
          !this.businessInfo?.businessWebsite,
        address: this.isAddressValid,
        category: this.businessInfo?.businessCategory?.length !== 0,
        state: this.businessInfo?.businessState?.length !== 0,
        lga: this.businessInfo?.businessLga?.length !== 0
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    }
  },
  watch: {
    "businessInfo.state"(value) {
      this.states.forEach(state => {
        if (state.name === value) {
          this.lga = state.locals.map(lga => lga);
        }
      });
    }
  },
  mounted() {
    this.googleAutocomplete();
    this.getBusinessCategories();
    this.listState();
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("auth", ["updateInfo"]),
    listState() {
      this.states = Location.map(location => location.state);
    },
    editInfo() {
      this.disabled = false;
    },

    // Google Autocomplete functions starts here
    validateAddress() {
      if (this.isAddressValid) {
        this.errorOnAddress = false;
        document.getElementById("address").style.borderColor = "#000";
      } else {
        this.errorOnAddress = true;
        document.getElementById("address").style.borderColor = "#C70039";
      }
    },
    handleError() {
      if (this.isAddressValid) {
        this.businessInfo.address = "";
        this.businessInfo.state = "";
        this.businessInfo.lga = "";
      }
      document.getElementById("address").style.borderColor = "#000";
      this.errorOnAddress = false;
      this.isAddressValid = false;
    },
    googleAutocomplete() {
      document.getElementById("address").placeholder = "";

      let options = {
        // types: ["geocode"],
        componentRestrictions: { country: "ng" }
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        options
      );
      this.autocomplete.addListener("place_changed", () => {
        this.isAddressValid = true;
        this.validateAddress();
        let place = this.autocomplete.getPlace();
        // console.log(place);
        let ac = place.address_components;
        const street = {
          no: "",
          name: "",
          neighborhood: ""
        };

        ac.forEach(address => {
          if (address.types[0] === "street_number") {
            street.no = address.long_name;
          }
          if (address.types[0] === "route") {
            street.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            street.neighborhood = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            this.businessInfo.state = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            this.businessInfo.lga = address.long_name;
          }
        });

        // this.businessInfo.address =
        //   street.no + " " + street.name + ", " + street.neighborhood;
        this.businessInfo.address = place.name;
        if (this.businessInfo.state === "" || this.businessInfo.lga === "") {
          this.isAddressValid = false;
          this.showAlert({
            display: true,
            description:
              "Please select a valid street address from the dropdown",
            type: "error"
          });
        }
      });
    },
    // Google Autocomplete functions ends here

    // getBusinessInfo() {
    //   GET_BUSINESS_INFO()
    //     .then((response) => {
    //       if (response.data.data) {
    //         this.businessInfo = response.data.data;
    //       }
    //     })
    //     .catch(() => {});
    // },
    getBusinessCategories() {
      GET_BUSINESS_CATEGORIES()
        .then(res => {
          this.options = res.data.data;
        })
        .catch(() => {});
    },

    updateBusinessInfo() {
      this.loading = true;
      if (
        this.businessInfo.businessWebsite === "" ||
        !this.businessInfo.businessWebsite
      ) {
        this.businessInfo.businessWebsite = null;
      } else {
        const website = this.businessInfo?.businessWebsite.toLowerCase();
        if (
          website.indexOf("http://") == 0 ||
          website.indexOf("https://") == 0
        ) {
          this.businessInfo.businessWebsite = website;
        } else {
          this.businessInfo.businessWebsite = "http://" + website;
        }
      }
      this.businessInfo.website = this.businessInfo.businessWebsite;
      UPDATE_BUSINESS_INFO(this.businessInfo)
        .then(response => {
          this.loading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Business information updated succesfully`
          });
          this.updateInfo(response.data.data);
          this.disabled = true;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <div v-if="loader">
      <Skeleton width="60" height="1.2rem" class="mb-8" />
      <div v-for="index in 4" :key="index" class="md:mt-16">
        <Skeleton width="60" height="1.2rem" />
        <Skeleton width="60%" height="1.2rem" class="mt-4" />
      </div>
    </div>
    <div v-else>
      <div class="flex items-center justify-between">
        <h4 class="font-semibold text-lg text-black">Account details</h4>
        <!-- <div>
        <Button
          v-if="disabled"
          text="Change Account"
          p="py-2 px-6"
          radius="rounded-3xl"
          fontSize="text-sm"
          background="bg-lightRed"
          color="text-brandRed"
          @click="disabled === false"
        />
        <Button
          v-if="disabled === false"
          text="Save info"
          p="py-2 px-6"
          radius="rounded-3xl"
          width="w-28"
          fontSize="text-sm"
          :loading="loading"
          @click="updateBusinessInfo()"
        />
      </div> -->
      </div>
      <div class="mt-4">
        <Input
          width="w-full"
          placeholder="First Name"
          class="mt-14"
          :disabled="disabled"
          v-model="merchantUser.firstName"
        />
        <Input
          width="w-full"
          placeholder="Last Name"
          class="mt-11"
          :disabled="disabled"
          v-model="merchantUser.lastName"
        />
        <Input
          width="w-full"
          placeholder="Email Address"
          class="mt-11"
          :disabled="disabled"
          v-model="merchantUser.email"
        />
        <Input
          width="w-full"
          placeholder="Phone Number"
          class="mt-11"
          :disabled="disabled"
          :value="
            businessInfo.phoneNumber ? businessInfo.phoneNumber : notAvailable
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Input from "@/UI/Input";
import Button from "@/UI/Button";
// import { GET_BUSINESS_INFO } from "@/utils/api/settings.js";
export default {
  components: {
    Input,
    Button
  },
  data: () => ({
    disabled: true,
    notAvailable: "N/A",
  }),
  props: {
    loader: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    ...mapState({
      merchantUser: state => state?.auth?.user?.merchantUser,
      businessInfo: state => state?.auth?.user?.merchant
    })
  }
};
</script>

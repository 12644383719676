<template>
  <div>
    <div v-if="loader">
      <Skeleton width="60" height="1.2rem" class="mb-8" />
      <div v-for="index in 4" :key="index" class="md:mt-16">
        <Skeleton width="60" height="1.2rem" />
        <Skeleton width="60%" height="1.2rem" class="mt-4" />
      </div>
    </div>
    <div v-else>
      <div v-if="showEnableWebhook" class="h-full">
        <div class="flex justify-center items-center h-full">
          <div class="mt-20">
            <p class="text-center mb-12 text-base md:text-lg text-grey">
              This is currently disabled for your account. <br />
              Click on the button below to enable
            </p>
            <Button
              text="Enable API setup"
              :shadow="true"
              class="mx-auto mb-10 md:mb-0"
              width="w-56"
              p="px-8 py-3"
              :loading="loading"
              @click="enableWebhookAccess()"
            />
          </div>
        </div>
      </div>
      <div v-if="showWebhookForm">
        <h4 class="font-semibold text-lg text-black">API configuration</h4>
        <div class="grid grid-cols-10">
          <Input
            type="text"
            id="merchantOutletId"
            width="w-full"
            placeholder="Merchant Outlet ID"
            class="mt-14 col-span-8"
            disabled="disabled"
            v-model="merchant.merchantOutlet.outletTag"
          />
          <div class="col-span-2 place-self-end">
            <Button
              text="Copy"
              p="py-2 px-3 md:px-6"
              class=""
              radius="rounded-3xl"
              width="md:w-20"
              fontSize="text:xs md:text-sm"
              background="bg-lightPurple"
              color="text-brandPurple"
              @click="copy( merchant.merchantOutlet.outletTag)"
            />
          </div>
        </div>

        <div class="grid grid-cols-10">
          <Input
            type="text"
            id="publickey"
            width="w-full"
            placeholder="Public key"
            class="mt-14 col-span-8"
            disabled="disabled"
            v-model="webhookInfo.publicKey"
          />
          <div class="col-span-2 place-self-end">
            <Button
              text="Copy"
              p="py-2 px-3 md:px-6"
              class=""
              radius="rounded-3xl"
              width="md:w-20"
              fontSize="text:xs md:text-sm"
              background="bg-lightPurple"
              color="text-brandPurple"
              @click="copy(webhookInfo.publicKey)"
            />
          </div>
        </div>
        <div class="grid grid-cols-10">
          <Input
            type="password"
            id="secret"
            width="w-full"
            placeholder="Secret key"
            class="mt-11 col-span-8"
            disabled="disabled"
            v-model="webhookInfo.secretKey"
            revealPassword
          />
          <div class="col-span-2 place-self-end">
            <Button
              text="Copy"
              p="py-2 px-3 md:px-6"
              radius="rounded-3xl"
              width="md:w-20"
              fontSize="text:xs md:text-sm"
              background="bg-lightPurple"
              color="text-brandPurple"
              @click="copy(webhookInfo.secretKey)"
            />
          </div>
        </div>

        <h4 class="font-semibold text-lg text-black mt-12">
          Webhook configuration
        </h4>

        <div class="relative">
          <Input
            type="text"
            width="w-full"
            placeholder="Webhook URL"
            class="mt-14"
            :disabled="disabled"
            v-model="webhookInfo.webhook"
          />
          <Button
            v-if="disabled"
            text="Edit"
            p="py-2 px-3 md:px-6"
            class="edit"
            radius="rounded-3xl"
            width="md:w-20"
            fontSize="text:xs md:text-sm"
            background="bg-lightPurple"
            color="text-brandPurple"
            @click="editInfo()"
          />
          <Button
            v-else
            text="Save"
            p="py-2 px-3 md:px-6"
            radius="rounded-3xl"
            class="edit"
            width="md:w-20"
            fontSize="text-xs md:text-sm"
            :loading="updateLoading"
            @click="updateWebhookAccess()"
          />
        </div>

        <div class="grid grid-cols-10">
          <Input
            type="password"
            id="secrethash"
            width="w-full"
            placeholder="Secret hash"
            class="mt-11 col-span-8"
            disabled="disabled"
            v-model="webhookInfo.encryptionKey"
            revealPassword
          />
          <div class="col-span-2 place-self-end">
            <Button
              text="Copy"
              p="py-2 px-3 md:px-6"
              class=""
              radius="rounded-3xl"
              width="md:w-20"
              fontSize="text:xs md:text-sm"
              background="bg-lightPurple"
              color="text-brandPurple"
              @click="copy(webhookInfo.encryptionKey)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // GET_WEBHOOK_DETAILS,
  ENABLE_WEBHOOK_ACCESS,
  UPDATE_WEBHOOK_ACCESS,
} from "@/utils/api/settings.js";
import { mapState, mapActions } from "vuex";
import SwitchInput from "@/UI/SwitchInput";
import Button from "@/UI/Button";
import Hr from "@/UI/Hr";
import Input from "@/UI/Input";
import copy from "@/mixins/copy";

export default {
  mixins: [copy],
  components: {
    SwitchInput,
    Hr,
    Button,
    Input,
  },
  data: () => ({
    turnApi: false,
    loading: false,
    disabled: true,
    updateLoading: false,
    loader: true,
  }),
  computed: {
    ...mapState({
      liveMode: (state) => state?.auth?.liveMode,
      webhookInfo: (state) => state?.settings?.webhookInfo,
      showEnableWebhook: (state) => state?.settings?.showEnableWebhook,
      showWebhookForm: (state) => state?.settings?.showWebhookForm,
      merchant: (state) => state?.auth?.user,
    }),
  },
  mounted() {
    this.getWebhookDetails();
  },
  methods: {
    ...mapActions("settings", ["getWebhookInfo"]),
    ...mapActions("notification", ["showAlert"]),
    editInfo() {
      this.disabled = false;
    },
    getWebhookDetails() {
      this.getWebhookInfo({ liveMode: this.liveMode })
        .then((res) => {
          if (res.data.data) {
            this.loader = false;
          }
        })
        .catch((err) => {
          if (err.response.data.errorCode === "NO_PROGRAMMATIC_ACCESS") {
            this.loader = false;
          }
        });
    },

    enableWebhookAccess() {
      this.loading = true;
      ENABLE_WEBHOOK_ACCESS({
        data: {
          webhookUrl: "",
        },
        liveMode: this.liveMode,
      })
        .then((res) => {
          this.getWebhookInfo({ liveMode: this.liveMode });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateWebhookAccess() {
      this.updateLoading = true;
      UPDATE_WEBHOOK_ACCESS({
        data: {
          webhookUrl: this.webhookInfo.webhook,
        },
        liveMode: this.liveMode,
      })
        .then(() => {
          this.updateLoading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Webhook URL updated succesfully`,
          });
          this.disabled = true;
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    truncate(text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
};
</script>

<style scoped>
.edit {
  position: absolute;
  top: -4rem;
  right: 0;
}
</style>

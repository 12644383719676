<template>
  <div>
    <h3 class="font-semibold text-lg text-black">Link Account</h3>
    <transition name="slideUp" mode="out-in">
      <form
        @submit.prevent="current = 'confirm'"
        class="mt-4"
        v-if="current === 'form'"
      >
        <Input class="mt-8" placeholder="Account number" />
        <Input class="mt-8" placeholder="Bank" />
        <Button
          class="mt-16"
          text="Add account"
          width="w-full"
          @click="current = 'confirm'"
          type="submit"
        />
      </form>
      <div v-if="current === 'confirm'">
        <Card background="lightGrey-1" class="mt-4" p="p-2">
          <div class="flex items-center">
            <img
              class="h-8 w-8 mr-4"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1624102557/customer/Group_491_1_bwyplz.svg"
              alt="profile"
            />
            <div>
              <p class="font-semibold">Lagbaja Tamedo</p>
              <p class="mt-2">081******233</p>
            </div>
          </div>
        </Card>
        <div class="mt-20">
          <p class="text-center font-semibold">No this is not me?</p>
          <Button
            text="Yes this is my account"
            width="w-full"
            @click="submit"
            class="mt-4"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Input from "@/UI/Input";
import Button from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data: () => ({
    current: "form",
  }),
  methods: {
    ...mapActions("notification", ["showAlert"]),
    submit() {
      this.showAlert({
        display: true,
        type: "success",
        description: `Account Successfully Linked`,
      });
      this.$emit("completed");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
</style>

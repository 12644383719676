<template>
  <div class="h-full">
    <div v-if="loader">
      <Skeleton width="60" height="1.2rem" class="mb-8" />
      <div v-for="index in 3" :key="index" class="md:mt-16">
        <Skeleton width="60" height="1.2rem" />
        <Skeleton width="60%" height="1.2rem" class="mt-4" />
      </div>
    </div>
    <div v-else>
      <div class="flex items-center justify-between">
        <h4 class="font-semibold text-lg text-black">Account details</h4>
        <div>
          <Button
            v-if="disabled"
            :text="bank.number ? 'Change Account' : 'Add account'"
            p="py-2 px-6"
            radius="rounded-3xl"
            fontSize="text-sm"
            background="bg-lightPurple"
            color="text-brandPurple"
            @click="editInfo()"
          />
          <Button
            v-if="disabled === false"
            text="Save info"
            p="py-2 px-6"
            radius="rounded-3xl"
            width="w-28"
            fontSize="text-sm"
            :loading="loading"
            @click="saveInfo()"
            :disabled="disableButton"
          />
        </div>
      </div>
      <div class="mt-4">
        <Select
          class="mt-14"
          width="w-full"
          label="prettyName"
          placeholder="Bank"
          :options="bankList"
          :reduce="(bank) => bank.id"
          @input="bankChanged"
          v-model="bank.bank.id"
          :disabled="disabled"
          errorText="Please select a bank"
          :validation="rules.bank"
          @valid="valid.bank = $event"
        />
        <Input
          width="w-full"
          placeholder="Account number"
          class="mt-11"
          :disabled="disabled"
          v-model="bank.number"
          errorText="Please enter a valid account number"
          :validation="rules.number"
          @valid="valid.number = $event"
        />

        <Input
          width="w-full"
          placeholder="Account name"
          class="mt-11"
          disabled="disabled"
          v-model="bank.name"
          errorText="Must be a valid account name"
          :validation="rules.name"
          @valid="valid.name = $event"
        />
      </div>

      <Modal :display="showUpdateSuccess" @close="showUpdateSuccess = false">
        <h5 class="text-black text-lg font-semibold">
          We have sent a code to your email ({{ merchant.email }})
        </h5>

        <p class="mt-4 text-sm">
          Just to be sure you own the bank account provided.
        </p>
        <Input
          class="mt-8"
          placeholder="Enter OTP"
          width="w-full"
          v-model="bank.otp"
          type="number"
          errorText="OTP must be 6 characters"
          :validation="otp.otpValid"
          @valid="otpValid = $event"
        />

        <Button
          text="Continue"
          width="w-full"
          class="my-8"
          @click="verifyOtp"
          :loading="verifyLoading"
          :disabled="disableOtpButton"
        />
      </Modal>
    </div>
  </div>
</template>
<script>
import {
  GET_ALL_BANKS,
  GET_BANK_ACCOUNT_NAME,
  SAVE_ACCOUNT_INFO,
  GET_USER_CASHOUT_ACCOUNT,
} from "@/utils/api/settings.js";
import { mapState, mapActions } from "vuex";
import Input from "@/UI/Input";
import Modal from "@/UI/Modal";
import Button from "@/UI/Button";
import Select from "@/UI/Select";

export default {
  components: {
    Input,
    Button,
    Modal,
    Select,
  },
  data: () => ({
    disabled: true,
    loading: false,
    bankList: [],
    showUpdateSuccess: false,
    verifyLoading: false,
    loader: true,
    bank: {
      bank: {},
      number: "",
      name: "",
    },
    valid: {
      number: false,
      bank: false,
      name: false,
    },
    otpValid: false,
  }),
  computed: {
    ...mapState({
      merchantOutletId: (state) => state?.auth?.user?.merchantOutlet.id,
      merchant: (state) => state?.auth?.user?.merchantUser,
    }),
    getAccountName() {
      if (this.bank?.number?.length === 10 && this.bank?.bank?.id !== null) {
        return true;
      } else return false;
    },
    rules() {
      return {
        number: this.bank?.number?.length === 10,
        bank: this.bank?.bank?.id?.length !== 0,
        name: this.bank?.name !== null && this.bank?.name !== "",
      };
    },
    otp() {
      return {
        otpValid: this.bank?.otp?.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otp).includes(false);
    },
  },
  watch: {
    getAccountName() {
      if (this.getAccountName & !this.disabled) {
        this.getBankDetails();
      }
    },
  },
  created() {
    this.getBanks();
    this.getAccountInfo();
  },
  methods: {
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("auth", ["updateAccountInfo"]),
    ...mapActions("notification", ["showAlert"]),
    editInfo() {
      this.disabled = false;
    },
    bankChanged() {
      this.bank.number = "";
      this.bank.name = "";
    },
    getAccountInfo() {
      GET_USER_CASHOUT_ACCOUNT(this.merchantOutletId)
        .then((response) => {
          if (response.data.data) {
            this.bank = response.data.data;
          }
        })
        .catch(() => {});
    },
    getBanks() {
      GET_ALL_BANKS()
        .then((res) => {
          this.bankList = res.data.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBankDetails() {
      this.setLoading(true);
      GET_BANK_ACCOUNT_NAME({
        accountNumber: this.bank.number,
        bankId: this.bank.bank.id,
      })
        .then((res) => {
          this.setLoading(false);
          this.bank.name = res.data.data.accountName;
        })
        .catch(() => {
          this.setLoading(false);
          this.bank.name = "";
        });
    },
    saveInfo() {
      this.loading = true;
      SAVE_ACCOUNT_INFO(this.merchantOutletId, {
        accountNumber: this.bank.number,
        accountName: this.bank.name,
        bankId: this.bank.bank.id,
      })
        .then((response) => {
          this.loading = false;
          if (response.data.data.sendOtpResponse) {
            this.showUpdateSuccess = true;
          } else {
            this.showAlert({
              display: true,
              type: "success",
              description: `Cashout Account details updated succesfully`,
            });
            this.getAccountInfo();
            // this.updateAccountInfo(response.data.data.merchantOutlet);
            this.disabled = true;
          }
        })
        .catch(() => {
          this.merchantAccount = {};
          this.loading = false;
        });
    },
    verifyOtp() {
      this.verifyLoading = true;
      SAVE_ACCOUNT_INFO(this.merchantOutletId, {
        accountNumber: this.bank.number,
        accountName: this.bank.name,
        bankId: this.bank.bank.id,
        otp: this.bank.otp,
      })
        .then((response) => {
          this.showUpdateSuccess = false;
          this.verifyLoading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Cashout Account details updated succesfully`,
          });
          this.bank.otp = "";
          this.getAccountInfo();
          // this.updateAccountInfo(response.data.data.merchantOutlet);
          this.disabled = true;
        })
        .catch(() => {
          this.verifyLoading = false;
        });
    },
  },
};
</script>

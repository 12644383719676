<template>
  <div class="body-container gap-4">
    <Card class="h-full">
      <h3 class="text-black font-semibold text-lg">My Profile</h3>
      <Hr />
      <div class="grid text-left grid-cols-1 md:grid-cols-2">
        <div class="flex items-center">
          <div
            class="rounded-full border inline-block border-lightRed p-2 mr-4"
          >
            <!-- <div v-if="loader">
              <Skeleton class="rounded-full h-20 w-20 flex items-center justify-center bg-white" />
            </div> -->
            <img
              v-if="merchant.logoId"
              class="h-20 w-20 rounded-full"
              :src="logo"
              alt=""
            />
            <img
              v-else
              class="h-20 w-20 rounded-full"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1635580718/merchant/merchant-default-logo_nlrlpm.svg"
              alt=""
            />
          </div>
          <div>
            <h4 class="text-black font-semibold">{{ merchant.name }}</h4>
            <p class="font-semibold text-lightPurple-2 text-sm mt-1 md:mt-0">
              {{ email }}
            </p>
            <!-- <p
              class="text-brandRed text-opacity-75 text-sm font-semibold mt-2 md:mt-0"
            >
              Profile - 75% Completed
            </p> -->
          </div>
        </div>
        <!-- <div class="flex justify-center mt-2 md:mt-0 md:justify-end">
          <div>
            <Button
              text="Complete Profile"
              radius="rounded-3xl"
              p="py-2 px-6"
            />
          </div>
        </div> -->
      </div>
      <div class="nav mt-8">
        <div
          class="settings md:grid grid-cols-5 justify-items-center overflow-x"
        >
          <p
            class="
              tab
              text-lightPurple-2
              cursor-pointer
              relative
              transition-all
            "
            :class="{ personal: settingType === 'business' }"
            @click="handleClick('business')"
          >
            Business Info
          </p>
          <p
            class="
              tab
              text-lightPurple-2
              cursor-pointer
              relative
              transition-all
            "
            :class="{ personal: settingType === 'personal' }"
            @click="handleClick('personal')"
          >
            Personal Info
          </p>
          <p
            class="
              tab
              text-lightPurple-2
              relative
              cursor-pointer
              transition-all
            "
            :class="{ personal: settingType === 'account' }"
            @click="handleClick('account')"
          >
            Account
          </p>
          <p
            class="
              tab
              text-lightPurple-2
              relative
              cursor-pointer
              transition-all
            "
            :class="{ personal: settingType === 'api' }"
            @click="handleClick('api')"
          >
            API & Webhook
          </p>
          <p
            class="
              tab
              text-lightPurple-2
              relative
              cursor-pointer
              transition-all
            "
            :class="{ personal: settingType === 'team' }"
            @click="handleClick('team')"
          >
            Team
          </p>
          <!-- <p class="text-lightPurple-2 relative  cursor-pointer transition-all">
            Security
          </p> -->
        </div>
        <Hr />
        <Card class="mt-4" p="p-0 md:p-4">
          <transition name="slideUp" mode="out-in">
            <PersonalInfo
              :businessInfo="businessInfo"
              :loader="loader"
              v-if="settingType === 'personal'"
            />
            <BusinessInfo
              :businessInfo="businessInfo"
              :loader="loader"
              v-if="settingType === 'business'"
            />
            <!-- <Finance v-if="settingType === 'finance'" /> -->
            <Account v-if="settingType === 'account'" />
            <Api v-if="settingType === 'api'" />
            <Team v-if="settingType === 'team'" />
          </transition>
        </Card>
      </div>
    </Card>
    <div>
      <Card>
        <h3 class="text-black ont-bold text-lg">Security and Privacy</h3>
        <Hr />
        <ChangePassword />
      </Card>
      <Card class="mt-6">
        <div class="flex items-center my-6 justify-between">
          <div>
            <p class="font-semibold">
              Switch live mode
              <br /><span class="text-sm font-light"
                >Live mode is <span v-if="liveMode">on</span>
                <span v-if="!liveMode">off</span></span
              >
            </p>
          </div>

          <SandboxSwitch />
        </div>
        <p
          class="
            font-light
            text-center text-sm
            mt-2
            text-brandRed
            rounded-full
            bg-lightRed
            px-6
            py-2
          "
          v-if="!activatedStore"
        >
          Activate your store from pending tasks to enable this feature
        </p>
        <p
          class="
            font-light
            text-center text-sm
            mt-2
            text-brandRed
            rounded-full
            bg-lightRed
            px-6
            py-2
          "
          v-else-if="kycStatus === 'PENDING'"
        >
          We're still validating your account. You will be notified
        </p>
        <p
          class="
            font-light
            text-center text-sm
            mt-2
            text-brandRed
            rounded-full
            bg-lightRed
            px-6
            py-2
          "
          v-else-if="kycStatus === 'DECLINED'"
        >
          We are unable to verify your business account. Contact support
        </p>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import PersonalInfo from "./PersonalInfo";
import BusinessInfo from "./BusinessInfo";
import ChangePassword from "./ChangePassword";
import Finance from "./Finance";
import Account from "./Account";
import Team from "./Team";
import Api from "./Api";
import Hr from "@/UI/Hr";
import Button from "@/UI/Button";
import Select from "@/UI/Select";
import SandboxSwitch from "@/Layouts/SandboxSwitch.vue";
import { GET_BUSINESS_INFO } from "@/utils/api/settings.js";

export default {
  components: {
    Hr,
    Button,
    PersonalInfo,
    BusinessInfo,
    Team,
    Finance,
    Account,
    Api,
    SandboxSwitch,
    ChangePassword,
    Select
  },
  data: () => ({
    loader: true,
    businessInfo: {
      businessCategory: "",
      businessWebsite: "",
      address: "",
      name: "",
      state: "",
      lga: ""
    }
  }),
  computed: {
    ...mapState({
      merchant: state => state?.auth?.user?.merchant,
      email: state => state?.auth?.user?.merchantUser?.email,
      liveMode: state => state?.auth?.liveMode,
      settingType: state => state?.auth?.currentTab,
      activatedStore: state => state?.auth?.activatedStore,
      kycStatus: state => state?.auth?.kycStatus
    }),
    logo() {
      return `${process.env.VUE_APP_BASE_URL}/media/image/${this.merchant.logoId}`;
    }
  },
  mounted() {
    this.getBusinessInfo();
  },

  methods: {
    ...mapActions("auth", ["setMode", "setCurrentTab", "updateInfo"]),
    ...mapActions("notification", ["showToast"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("settings", ["getWebhookInfo"]),
    getBusinessInfo() {
      GET_BUSINESS_INFO()
        .then(response => {
          if (response.data.data) {
            this.businessInfo = response.data.data;
            this.updateInfo(response.data.data);
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    handleClick(newTab) {
      this.getBusinessInfo();
      this.setCurrentTab(newTab);
    }
  }
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.body-container {
  display: grid;
  grid-template-columns: 70% 1fr;
  min-height: calc(100vh - 10rem);
}
.personal {
  font-weight: 600;
}
.personal:after {
  /* content: ""; */
  position: absolute;
  content: "";
  height: 4px;
  border-radius: 4px;
  bottom: -0.65rem;
  margin: 0 auto;
  left: 0;
  /* right: 0; */
  width: 100%;
  background: #6956c8;
  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 768px) {
  .body-container {
    grid-template-columns: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .settings {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .settings .tab {
    flex: 0 0 auto;
    margin-right: 2px;
    padding: 5px 10px;
  }

  .personal {
    background: #6956c8;
    border-radius: 20px;
    color: #fff;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .personal:after {
    bottom: 0;
    background: none;
  }
}
</style>

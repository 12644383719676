<template>
  <div class="container-body">
    <div class="flex items-center justify-between">
      <p class="text-lightPurple-2 font-semibold">My Cards</p>
      <div class="controls flex items-center">
        <div class="button-round p-1 mr-3" @click="previous">
          <img
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1624012023/customer/Vector_15_c0u4go.svg"
            alt="previous"
          />
        </div>
        <div class="button-round p-1" @click="next">
          <img
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1624012221/customer/Vector_15_1_pkuwqn.svg"
            alt="next"
          />
        </div>
      </div>
    </div>
    <div class="wrapper mt-6" v-if="hideCarousel">
      <!-- <Flickity ref="flickity" :options="flickityOptions">
        <div class="carousel-cell first-card">1</div>
        <div class="carousel-cell add-card">
          <div class="text-center" @click="showLinkCard = true">
            <p class=" text-lightPurple-2 text-2xl">+</p>
            <p class="font-semibold  text-lightPurple-2">
              Add Card
            </p>
          </div>
        </div>
  
      </Flickity> -->
    </div>
    <div class="mt-8">
      <div class="flex items-center justify-between">
        <p class="font-semibold text-lightPurple-2">My Account</p>
        <div>
          <Button
            text="Add account"
            background="lightRed"
            radius="rounded-3xl"
            color="brandPurple"
            p="py-1 px-3"
            @click="showLinkAccount = true"
          />
        </div>
      </div>
    </div>
    <div class="accounts mt-4">
      <Card background="lightPurple">
        <div class="flex justify-between items-center">
          <div>
            <p class="account-number font-semibold text-lg text-lightPurple-2">
              09845245 - GTB
            </p>
            <p class="mt-2 text-lightPurple-2 text-sm font-semibold opacity-70">
              Lagbaja Tamedo Lagbaja
            </p>
          </div>
          <img
            class="h-4 w-4 cursor-pointer "
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1624097846/customer/Vector_57_zdvb8b.svg"
            alt="options"
          />
        </div>
      </Card>
    </div>

    <!-- Modal to link card -->
    <Modal :display="showLinkCard" @close="showLinkCard = false">
      <LinkCard @completed="showLinkCard = false" />
    </Modal>

    <!-- modal to link account -->
    <Modal :display="showLinkAccount" @close="showLinkAccount = false">
      <LinkAccount @completed="showLinkAccount = false" />
    </Modal>
  </div>
</template>
<script>
  import LinkCard from "./LinkCard";
  import LinkAccount from "./LinkAccount";
  // import Flickity from "vue-flickity";
  import Button from "@/UI/Button";
  import Modal from "@/UI/Modal";

  export default {
    components: {
      // Flickity,
      Button,
      Modal,
      LinkCard,
      LinkAccount,
    },
    data: () => ({
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "center",
      },
      showLinkCard: false,
      showLinkAccount: false,
      hideCarousel: true,
    }),
    methods: {
      next() {
        this.$refs.flickity.next();
      },

      previous() {
        this.$refs.flickity.previous();
      },
    },
    beforeDestroy() {
      this.hideCarousel = false;
    },
  };
</script>
<style scoped>
  .hide-carousel {
    display: none;
  }
  .container-body {
    overflow: hidden;
  }
  .first-card {
    background-color: #b692ef;
  }
  .carousel-cell {
    height: 12rem;
    border-radius: 16px;
    padding: 1rem;
    width: 20rem;
    /* background-color: red; */
    margin-right: 10px;
  }
  .add-card {
    background-color: white;
    border: 1px dashed #bcace3;
    box-shadow: 0px 5px 20px rgba(97, 55, 187, 0.06);
    border-radius: 16px;
    display: grid;
    place-items: center;
  }

  .button-round {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: grid;
    place-items: center;
    background-color: #f6f3ff;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .carousel-cell {
      width: 75%;
      height: 8rem;
    }
  }
  /* @import url("node_modules/@egjs/flicking/dist/flicking-inline.css"); */
</style>

<template>
  <div>
    <!-- <transition name="fadeIn" mode="out-in"> -->
    <div v-if="!completed" key="form">
      <h4 class="font-semibold text-lg">Add Card</h4>
      <form @submit.prevent="submit" class="mt-6">
        <Input placeholder="Card name" type="text" class="mt-6" />
        <Input placeholder="Card Number" type="text" class="mt-6" />
        <div class="grid grid-cols-2 gap-4 mt-6">
          <Input placeholder="Expiry date" />
          <Input placeholder="CVV" />
        </div>
        <Button class="mt-16" text="Add card" @click="submit" width="w-full" type="submit" />
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Button from "@/UI/Button";
import Input from "@/UI/Input";
export default {
  components: {
    Button,
    Input,
  },
  data: () => ({
    completed: false,
  }),
  methods: {
    ...mapActions("notification", ["showAlert"]),
    submit() {
      this.showAlert({
        display: true,
        type: "success",
        description: " Card Successfully added",
      });
      this.$emit("completed");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
</style>
